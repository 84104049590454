import development from './env.development';
import production from './env.production';
import staging from './env.staging';

const env = process.env.REACT_APP_ENV || 'development' ;

const config = {
    development,
    production,
    staging
};

export default config[env];

