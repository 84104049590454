import React, {useEffect, useState} from "react";
import {Button} from "primereact/button";
import {useNavigate} from "react-router-dom";
import './Home.scss';
import {Card} from "primereact/card";
// import Keycloak from "../keycloak";


export function Home() {
    const [keycloak, setKeycloak] = useState<object>({})
    const [authenticated, setAuthenticated] = useState(false)

    // useEffect(() => {
    //     const keycloak = Keycloak;
    //     keycloak.init({onLoad: 'login-required'}).then(authenticated => {
    //         setKeycloak(keycloak)
    //         setAuthenticated(authenticated)
    //     })
    // }, [])

    const navigate = useNavigate();

    // function navigateToNewsletterCustomer() {
    //     navigate("/newsletter/customer");
    // }
    function navigateToRedaktion() {
        navigate("/redaktion-ai-chat");
    }
    function navigateToNewsletterExpanded() {
        navigate("/newsletter/expanded");
    }

    function navigateToNewsletterGpReport() {
        navigate("/newsletter/gp-report");
    }

    function navigateToWebsiteSponsoredArticle() {
        navigate("/websites/sponsored-article");
    }

    function navigateToWebsiteThemepage() {
        navigate("/websites/theme-page");
    }

    function navigateToSocialMediaYoutube() {
        navigate("/social-media/youtube");
    }

    // if (keycloak) {
    //     if (authenticated)
    console.log("Enviroment Status = " + process.env.REACT_APP_ENV);


    return (
        <div>

            <div style={{
                textAlign: 'center',
                marginTop: '130px',
                minWidth: 'fit-content'
            }}>

                <h2 style={{
                    fontWeight: '400',
                    color: 'rgba(0,0,0,0.7)',
                }}>Konradin KI-Service</h2>

            </div>

            <div style={{

                // backgroundSize: "cover"
                // paddingBottom: '1000px'
                marginBottom: '400px'
            }}>
                <Card
                    style={{
                        // backgroundColor: 'rgba(38,80,115,0.4)',
                        flex: '1',
                        marginLeft: '5%',
                        marginRight: '5%',
                        backgroundImage: "url('./images/Head.jpg')",
                        backgroundSize: "cover",
                        minWidth: 'fit-content',
                        // height: '400px'
                        minHeight: 'fit-content',


                    }}>

                    <div className="field grid"
                         style={{
                             // marginBottom: '100px'
                         }}
                    >


                        <Button label="Redaktion"
                                raised
                                className="bg-newsletter home-button"
                                onClick={navigateToRedaktion}
                                style={{
                                    backgroundColor: 'rgba(236,244,214,0.4)',
                                    flex: '1',
                                    margin: '1%',
                                    minWidth: '250px',
                                    height: '100px'
                                }}
                        ></Button>
                        <Button label="Verkauf"
                                raised
                                className="bg-newsletter home-button"
                                onClick={navigateToNewsletterExpanded}
                                disabled
                                style={{
                                    backgroundColor: 'rgba(38,80,115,0.4)',
                                    flex: '1',
                                    margin: '1%',
                                    color: 'dimgray',
                                    minWidth: '250px',
                                    height: '100px'
                                }}
                        ></Button>
                        <Button label="PM"
                                raised
                                className="bg-newsletter home-button"
                                onClick={navigateToNewsletterExpanded}
                                disabled
                                style={{
                                    backgroundColor: 'rgba(38,80,115,0.4)',
                                    flex: '1',
                                    margin: '1%',
                                    color: 'dimgray',
                                    minWidth: '250px',
                                    height: '100px'
                                }}
                        ></Button>

                    </div>


                    <div className="field grid">

                    </div>
                    <div className="field grid">
                    </div>
                </Card>


            </div>


        </div>

    );
    // else return (<div className='my-12'>Unable to authenticate!</div>)

    }


    // return (
    //     <>
    //         <div className='my-12'>Initializing Keycloak...</div>
    //     </>
    // )
// }