import {Card} from "primereact/card";
import React, {useEffect, useRef, useState} from "react";
import {Button} from "primereact/button";
import {InputTextarea} from "primereact/inputtextarea";
import {AiChatService} from "./services/AiChatService";
import {Panel} from "primereact/panel";
import {SelectButton} from "primereact/selectbutton";
import "./AiChat.css";
import {ProgressSpinner} from "primereact/progressspinner";
import {BlockUI} from "primereact/blockui";
import {Dropdown} from "primereact/dropdown";
import {ToggleButton} from "primereact/togglebutton";
import {Messages} from "primereact/messages";
import ReactMarkdown from 'react-markdown';
// import {ZIndexUtils} from "primereact/utils";
// import set = ZIndexUtils.set;
// import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
// import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism'; // You can choose other styles if you'd like


export function AiChat() {

    const [abortController, setAbortController] = useState(new AbortController());

    const toastRef = useRef<Messages>(null)

    const [selectedQuestionsValues, setSelectedQuestionsValues] = useState<string[]>([]);
    const [inputText, setInputText] = useState('');
    const [prompt, setPrompt] = useState('');
    const [additionalPrompt, setAdditionalPrompt] = useState('');

    const [checkedAdditionalPrompt, setCheckedAdditionalPrompt] = useState(false);
    const [isTextareaEnabled, setIsTextareaEnabled] = useState(false);

    const [checkedAdditionalSettings, setCheckedAdditionalSettings] = useState(true);
    const [isSettingsHidden, setIsSettingsHidden] = useState(true);

    const [model, setModel] = useState('gpt-4o');


    const [selectedPercentage, setSelectedPercentage] = useState('');

    const [responseData, setResponseData] = useState('');
    const [blockedPanel, setBlockedPanel] = useState(false);
    const [loading, setLoading] = useState(false);

    const [selectedLength, setSelectedLength] = useState({name: "50%", code: '50%'});
    const [lengths, setLengths] = useState([
        {name: "25%", code: '25%'},
        {name: "50%", code: '50%'},
        {name: "75%", code: '75%'}
    ]);

    const [selectedSocialMedia, setSelectedSocialMedia] = useState({
        name: "",
        code: 'Erstelle einen Teaser für Social Media aus dem folgenden Text'
    });
    const [socialMedias, setSocialMedias] = useState([
        {
            name: "Linkedin-Post",
            code: 'Für den folgenden Text, erstelle einen journalistischen, neutralen Linkedin-Post, der das Interesse der User weckt, damit sie auf den Link klicken und den Beitrag auf unserer Website lesen. Darüber hinaus soll der Post zum Kommentieren/Interagieren anregen. Benutze passende Emojis'
        },
        {
            name: "Beschreibung YouTube-Video",
            code: 'Verfasse anhand des folgenden Textes eine Beschreibung für ein Youtube-Video: Die Beschreibung sollte suchmaschinenoptimiert sein und mindestens drei Stichpunkte enthalten. Verwende auch Smileys, um den Text übersichtlicher zu gestalten. Textlänge maximal 1.000 Zeichen inkl. Leerzeichen'
        },
        {
            name: "Instagram-Post",
            code: 'Verfasse zu folgendem Text einen Instagram Post. Setzte Bullet-Points mit wichtigen Informationen und rege durch eine Frage zum Kommentieren an. Verwende maximal 400 Zeichen inkl. Leerzeichen'
        }
    ]);


    const models = [
        {name: "GPT 3.5", value: "gpt-3.5-turbo-0125"},
        {name: "GPT 4", value: "gpt-4-turbo"},
        {name: "GPT 4o", value: "gpt-4o"}
        // {name: "o1", value: "o1-preview"}
    ]


    const [temperature, setTemperature] = useState(0.8);
    const temperatureList = [
        {label: "0.1", value: 0.1},
        {label: "0.2", value: 0.2},
        {label: "0.3", value: 0.3},
        {label: "0.4", value: 0.4},
        {label: "0.5", value: 0.5},
        {label: "0.6", value: 0.6},
        {label: "0.7", value: 0.7},
        {label: "0.8", value: 0.8},
        {label: "0.9", value: 0.9},
        {label: "1.0", value: 0.99},
        {label: "1.1", value: 1.1},
        {label: "1.2", value: 1.2},
        {label: "1.3", value: 1.3},
        {label: "1.4", value: 1.4},
        {label: "1.5", value: 1.5},
        {label: "1.6", value: 1.6},
        {label: "1.7", value: 1.7},
        {label: "1.8", value: 1.8},
        {label: "1.9", value: 1.9},
        {label: "2.0", value: 1.99}
    ]


    const textList = [
        {label: "Kürze Text", value: "Kürze den folgenden Text"},
        {
            label: "Erstelle Teaser",
            value: "Erstelle einen Teaser für Social Media aus dem folgenden Text"
            // value: "Erstelle einen Teaser für Social Media aus dem folgenden Text und erkläre die Schritte, die Du bei der Erstellung durchgeführt hast."
        },
        {label: "Fasse zusammen", value: "Schreib eine Zusammenfassung für den folgenden Text"},
        {
            label: "Korrektorat",
            value: "Bitte überprüfe den folgenden Text auf Rechtschreib-, Zeichensetzungs- und Grammatikfehler und korrigiere diese gegebenenfalls"
        },
        {label: "Übersetze Text", value: "Übersetze den folgenden Text"},


        {
            label: "Schreibe Text im aktiven Modus",
            value: "Schreibe den folgenden Text im aktiven Modus"
        },
        // {label: "Füge humorvolle Elemente ein", value: "Füge in den folgenden Text humorvolle Elemente ein"},
        {
            label: "Formuliere Text formeller",
            value: "Formuliere den folgenden Text in einem sachlicheren Stil / einer sachlicheren Ausdrucksweise"
        },
// Verkauf Prompt
        // {
        //     label: "Verfasse überzeugende Verkaufsbeschreibung",
        //     value: "Verfasse eine überzeugende Verkaufsbeschreibung für das folgende Produkt"
        // },
        {label: "Erstelle Tweet / X", value: "Erstelle einen Tweet aus dem folgenden Text"},
        // {
        //     label: "Schreibe die ersten zwei Absätze",
        //     value: "Schreibe die ersten zwei Absätze der folgenden Kurzgeschichte"
        // },
        {
            label: "Gestalte Einleitung",
            value: "Bitte formuliere für den folgenden Text eine Interesse weckende Headline mit maximal fünf Wörtern sowie einen ansprechenden Teaser mit maximal 140 Zeichen. Mache jeweils drei Vorschläge. Headline und Teaser sollen den Leser dazu animieren, den ganzen Beitrag zu lesen"
        },
        {
            label: "Schreibe überzeugenden Call-to-action",
            value: "Schreibe einen überzeugenden Call-to-action basierend auf dem folgenden Text"
        }
    ];

    const wordSentenceList = [
        {label: "Liste 10 Synonyme auf", value: "Liste 10 Synonyme für das nächste Wort auf"},
        {label: "Liste 10 Antonyme auf", value: "Liste 10 Antonyme für das nächste Wort auf"},
        {label: "Liste 5 Satz Varianten ", value: "Liste fünf Satz-Varianten auf"},
        {
            label: "Erstelle 5 mögliche Fortsetzungen",
            value: "Erstelle fünf mögliche Fortsetzungen für den folgenden Satz"
        },
        {label: "Erstelle 5 Folgefragen", value: "Erstelle fünf Fragen zum Text"},
        {
            label: "Verwandle Text in einfach verständliche Sprache",
            value: "Schreibe den folgenden Text um, sodass er leichter lesbar und besser verständlich ist"
        },
        {label: "Generiere fünf Bullet Points", value: "Generiere fünf Bullet Points aus dem folgenden Text"}
    ];

    const seoList = [
        {
            label: "Optimiere Überschrift für SEO",
            value: "Erstelle fünf SEO-optimierte Überschriftenvarianten für die folgende Überschrift"
        },
        {
            label: "Kreiere 5 SEO-optimierte Überschriften",
            value: "Kreiere fünf SEO-optimierte Überschriften aus diesem Text"
        },
        {
            label: "Recherche-Hilfe für hochwertige Backlinks",
            value: "Erstelle fünf hochwertige Backlinks für folgenden Text"
        },
        {label: "Extrahiere 5 Schlüsselwörter", value: "Extrahiere fünf Schlüsselwörter aus folgendem Text"},

        // Identifiziere SEO-Fehler im Text, zeig in einer Liste an und stelle eine verbesserte Version bereit
        {
            label: "Identifiziere SEO Fehler in Text",
            value: "Identifiziere SEO-Fehler im Text, zeig Fehler in einer Liste an und stelle eine verbesserte Version bereit"
        },
        // {
        //     label: "Erstelle Liste mit SEO-optimierten Tags",
        //     value: "Erstelle eine Liste mit 10 potenziellen SEO-optimierten Tags für den folgenden Text zusammen"
        // },
        {
            label: "Überarbeite Text zur Verbesserung SEO-Positionierung",
            value: "Überarbeite den vorhandenen Text zur Verbesserung seiner SEO-Positionierung"
        },
        {
            label: "Erstelle SEO Title und Meta-Beschreibung",
            value: "Bitte formuliere für den folgenden Text einen sachlichen SEO-Title mit maximal 70 Zeichen (inklusive Leerzeichen) sowie eine prägnante Meta-Description mit maximal 170 Zeichen (inklusive Leerzeichen). Es ist sehr wichtig, dass du die Zeichenzahl einhältst und objektiv bleibst"
        }
    ];


    const aiChatService = new AiChatService();
    const [previewRadioButtonHidden, setPreviewRadioButtonHidden] = useState(true);
    const [lengthDropdownHidden, setLengthDropdownHidden] = useState(true);
    const [mediaSelectDropdownHidden, setMediaSelectDropdownHidden] = useState(true);
    const controller = new AbortController();

    const [hideNews, setHideNews] = useState(true)

    // useEffect(() => {
    //     const containsShortenText = selectedQuestionsValues.some(value =>
    //         value.includes('Kürze den folgenden Text')
    //     );
    //     // Update the state based on the check
    //     setPreviewRadioButtonHidden(!containsShortenText);
    //
    // }, [selectedQuestionsValues]);

    useEffect(() => {
        const containsShortenText = selectedQuestionsValues.some(value =>
            value.includes('Kürze den folgenden Text')
        );
        // console.log(JSON.stringify(selectedLength))
        setLengthDropdownHidden(!containsShortenText);
    }, [selectedQuestionsValues]);

    useEffect(() => {
        const containsCreateTeaser = selectedQuestionsValues.some(value =>
            value.includes('Erstelle einen Teaser für Social Media aus dem folgenden Text')
        );
        setMediaSelectDropdownHidden(!containsCreateTeaser);
    }, [selectedQuestionsValues]);


    // lengthDropdownHidden


    // const updateSelectedQuestionsValues = () => {
    //     if (selectedPercentage && selectedQuestionsValues.includes("Kürze den folgenden Text")) {
    //         // Find the index of "Kürze den folgenden Text" in the array
    //         const index = selectedQuestionsValues.indexOf("Kürze den folgenden Text");
    //
    //         // Create a copy of the array to avoid mutating the state directly
    //         const updatedValues = [...selectedQuestionsValues];
    //
    //         // Update the value at the specified index
    //         updatedValues[index] = `Kürze den folgenden Text auf ${selectedPercentage} Prozent`;
    //
    //         // Update the state with the modified array
    //         setSelectedQuestionsValues(updatedValues);
    //     }
    // };


    const blockPanel = () => {
        setBlockedPanel(true);
    }
    const unblockPanel = () => {
        setBlockedPanel(false);
    }

//------------------------------------------------------------------------------------------------------

    // Handle INPUT FIELD
    const handleInputText = (event: any) => {
        setInputText(event.target.value);
        setPrompt(event.target.value);

        // console.log(prompt + " is The Prompt");
    };
    // console.log(prompt + " is The Prompt");

//------------------------------------------------------------------------------------------------------

    // UPDATE PROMPT
    // function updatePrompt() {
    //     // let combinedPrompt = "";
    //     // Use the join method to concatenate array elements into a string
    //     const questions = selectedQuestionsValues.join(', und ') + ", kennzeichne alle Schritte, die Du gemacht hast (auf Deutsch) und mache sie fett (<b>).";
    //     console.log("questions are: " + questions)
    //     const combinedPrompt = questions + ": " + inputText;
    //     console.log("combined Prompt Variable is :" + combinedPrompt)
    //     setPrompt(combinedPrompt);
    //     console.log("combined Prompt is : " + prompt);
    //     return combinedPrompt;
    //
    // }


    const updatePrompt = () => {
        const updatedValues = [...selectedQuestionsValues];

        if (selectedLength && updatedValues.includes("Kürze den folgenden Text")) {
            const index = updatedValues.indexOf("Kürze den folgenden Text");
            updatedValues[index] = `Kürze den folgenden Text auf ${selectedLength.code} Länge`;
        }

        if (updatedValues.includes("Erstelle einen Teaser für Social Media aus dem folgenden Text")) {
            const index = updatedValues.indexOf("Erstelle einen Teaser für Social Media aus dem folgenden Text");

            updatedValues[index] = selectedSocialMedia.code;
        }


        let addPrompt = '';
        let addPromptLength = 0;
        if (additionalPrompt) {
            addPromptLength = 1;
            addPrompt = ", und " + additionalPrompt + ". ";
        }

        let separateResultsString = '';
        if (Number(updatedValues.length) + Number(addPromptLength) >= 2) {
            separateResultsString = ". Dann führe alle Prompts separat aus und liefere sie als separate Ergebnisse zurück";
        }

        // const questions = updatedValues.join(', und ') + addPrompt + separateResultsString + ". Kennzeichne für jeden Prompt alle Schritte, die Du gemacht hast (auf Deutsch), und mache die Kennzeichnung fett (<b>).";
        const questions = updatedValues.join(', und ') + addPrompt + separateResultsString;


        const combinedPrompt = questions + ' Der Text ist: "' + inputText + '"';


        setPrompt(combinedPrompt);
        return combinedPrompt;
    };


//------------------------------------------------------------------------------------------------------

    // RESET PROMPT - not in use
    function resetPrompt() {
        setPrompt("");
    }

//------------------------------------------------------------------------------------------------------

    // Handle SEND FIELD
    const handlePromptSend = () => {
        blockPanel();
        setLoading(true);
        console.log("input text is : " + inputText);
        console.log("------------------------------------");
        let currentPrompt = prompt;
        if (selectedQuestionsValues.length !== 0) {
            currentPrompt = updatePrompt();
        }
        // const { signal } = abortController;
        const currentAbortController = new AbortController();
        aiChatService.sendPromptToBackend(currentPrompt, model, temperature, currentAbortController.signal)
            .then((response: any) => {
                console.log("The response is : ", response);
                console.log("***************************************");
                setResponseData(response);
            })
            .catch((error: any) => {
                if (error.name === 'AbortError') {
                    console.log('Request was aborted');
                } else {
                    console.error("Error in AI chat service:", error.message);
                }
                // Show a toast message for the error
                if (toastRef.current) {
                    toastRef.current.show({
                        severity: 'error',
                        summary: 'Fehler',
                        // detail: 'Fehler',
                    });
                }
            })
            .finally(() => {
                unblockPanel();
                setLoading(false);
            });
        setAbortController(currentAbortController);
    };

//------------------------------------------------------------------------------------------------------
    useEffect(() => {
        // Cleanup function
        return () => {
            // Cancel the ongoing request when the component is about to unmount
            abortController.abort();
        };
    }, [abortController]);

    //------------------------------------------------------------------------------------------------------

    // // Handle SEND FIELD
    // const handlePromptSend = () => {
    //
    //     blockPanel();
    //     setLoading(true);
    //
    //     console.log("input text is : " + inputText);
    //     console.log("------------------------------------");
    //     // console.log("prompt is : " + prompt);
    //     // console.log("------------------------------------");
    //
    //     let currentPrompt = prompt;
    //     if (selectedQuestionsValues.length !== 0) {
    //         currentPrompt = updatePrompt();
    //     }
    //
    //     aiChatService.sendPromptToBackend(currentPrompt, model, temperature).then((response: any) => {
    //
    //         console.log("The response is : ", response);
    //         console.log("***************************************");
    //         setResponseData(response);
    //         unblockPanel();
    //         setLoading(false);
    //
    //     });
    // }

//------------------------------------------------------------------------------------------------------

    // const handleRadioButtonChange = (event: any) => {
    //     setSelectedPercentage(event.value);
    // };

//------------------------------------------------------------------------------------------------------

    function copyToClipboard() {
        navigator.clipboard.writeText(responseData);

    }

//------------------------------------------------------------------------------------------------------

    // const handleToggle = (e: any) => {
    //     setCheckedAdditionalPrompt(e.value);
    //     setIsTextareaEnabled(e.value);
    // };

//------------------------------------------------------------------------------------------------------

    const handleToggleSettings = (e: any) => {
        setCheckedAdditionalSettings(e.value);
        setIsSettingsHidden(e.value);
    }


//------------------------------------------------------------------------------------------------------
    const handleTemperatureChange = (e: any) => {
        setTemperature(e.value);
    }
//------------------------------------------------------------------------------------------------------


    // const handleCancelRequest = () => {
    //     controller.abort();
    // }
//------------------------------------------------------------------------------------------------------
    const stopBlocking = () => {
        abortController.abort();
        unblockPanel();
        setLoading(false);
    };

//------------------------------------------------------------------------------------------------------
//     const handleCancelRequestTwo = () => {
//         controller.abort();
//     }


    // useEffect(() => {
    //     const handleKeyPress = (event:any) => {
    //         if (event.key === 'Escape') {
    //             handleCancelRequest();
    //         }
    //     };
    //
    //     window.addEventListener('keydown', handleKeyPress);
    //
    //     return () => {
    //         window.removeEventListener('keydown', handleKeyPress);
    //     };
    // }, []);

//------------------------------------------------------------------------------------------------------
    function countCharactersAndWords(inputString: string) {
        // Remove leading and trailing whitespaces
        inputString = inputString.trim();

        // Count characters
        const characterCount = inputString.length;

        // Count words
        const wordCount = inputString.split(/\s+/).filter(word => word.length > 0).length;

        return {
            characters: characterCount,
            words: wordCount
        };
    }

//------------------------------------------------------------------------------------------------------
//
//     const showNewsUntil = new Date('2024-05-23T00:00:00'); // replace with your desired date
//
//     useEffect(() => {
//         // Get the current date
//         const currentDate = new Date();
// console.log("currentDate is : " + currentDate);
//         // Check if the current date is smaller than showNewsUntil
//         if (currentDate < showNewsUntil) {
//             setHideNews(false);
//         }
//     }, [showNewsUntil]);
//------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------
    const showNewsUntil = new Date('2024-06-06');
    useEffect(() => {
        // Get the current date
        const currentDate = new Date();
        // console.log("currentDate is: " + currentDate);

        // Check if the current date is smaller than showNewsUntil
        if (currentDate < showNewsUntil) {
            setHideNews(false);
        } else {
            setHideNews(true);
        }
    }, []); // Empty dependency array to run only once after initial render

//------------------------------------------------------------------------------------------------------

    // @ts-ignore
    return (
        <>

            <BlockUI blocked={blockedPanel}
                     template={<i className="pi pi-spin pi-globe" style={{'fontSize': '3rem'}}/>}>

                {/*{loading ? <ProgressSpinner strokeWidth="8" className="center-content"/> : false}*/}
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    {loading ? <ProgressSpinner strokeWidth="8"

                    /> : false}
                </div>
                <div className="aiChat" style={{
                    backgroundImage: "url('./images/chatGptBcg.jpg')",
                    minHeight: '800px',
                    padding: '30px',
                    // paddingBottom:'1500px',
                    // marginBottom:'1500px',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'

                }}>


                    {/*<p>{JSON.stringify(logfileInfo)} </p>*/}


                    <div style={{
                        textAlign: 'center'
                    }}>
                        <h2 style={{
                            fontWeight: '400',
                            color: 'rgba(0,0,0,0.7)',
                            textAlign: 'center',
                            // border: 'solid',
                            // borderStyle: 'solid',
                            // borderRadius: '3px',
                            // borderColor: 'rgba(38,80,115,0.32)',
                            // borderWidth: '5px',
                            // padding: '17px',
                            // backgroundColor: 'rgba(215,215,234,0.15)',
                            margin: '20px',
                            display: 'inline-block',
                            marginTop: '100px',
                            marginBottom: '5px'
                            // fontFamily: 'copperplate'
                        }}
                        > Redaktion KI Service
                        </h2>
                    </div>


                    <div
                        hidden={hideNews}
                    >
                        <h4 style={{
                            // backgroundColor: 'rgba(57,206,16,0.38)',
                            color: "green",
                            width: 'fit-content',
                            marginBottom: '0'
                            // borderStyle: "solid",
                            // borderColor: "red"
                        }}
                        >Neuigkeiten !</h4>

                        <ul style={{
                            backgroundColor: 'rgba(57,206,16,0.05)',
                            color: 'rgba(54,121,35,0.68)',
                            borderStyle: 'dotted',
                            borderColor: 'rgba(57,206,16,0.15)'
                            // width: 'fit-content',
                            // borderStyle: "solid",
                            // borderColor: "red"
                        }}>
                            <li>Die Anzahl der Zeichen der Anfrage und Antwort ist nicht mehr begrenzt</li>
                            <li>Die GPT-4 model AI Version wurde geändert - vorher waren die Trainingsdaten bis April
                                2023, jetzt sind sie bis Dezember 2023
                            </li>
                            <li>Die neue Version GPT-4o wird als Standard gesetzt, es ist das fortschrittlichste
                                GPT-Modell, das 2x schneller und viel effizienter ist. Trainingsdaten bis Oktober 2023
                            </li>
                            <li>Die maximale Temperatureinstellung war bis zu 1.0, jetzt bis zu 2.0</li>

                        </ul>

                    </div>
                    {/*<p>socialMedias : <br/>{JSON.stringify(socialMedias)} </p>*/}
                    {/*<p>----</p>*/}
                    {/*<p>selectedSocialMedia : <br/>{JSON.stringify(selectedSocialMedia)} </p>*/}
                    {/*<p>----</p>*/}
                    {/*<p>selectedQuestionsValues : <br/>{JSON.stringify(selectedQuestionsValues)} </p>*/}


                    <Card
                        style={{
                            backgroundColor: 'rgba(38,80,115,0.4)',
                            flex: '1',
                            marginTop: '50px',
                            // display: 'flex',
                            // flexWrap: 'wrap'
                            // marginRight: '5%',
                            // marginLeft: '5%',
                            minWidth: 'fit-content'

                        }}>

                        <div style={{
                            // backgroundColor: 'rgba(171,171,231,0.6)',
                            flex: '1',
                            display: 'flex',
                            flexWrap: 'wrap',

                        }}>


                            <Card title={"Aufgabe im Zusammenhang mit Textanalyse und -kreation"}
                                  className={"card-prompts"}
                                  style={{

                                      backgroundColor: 'rgba(236,244,214,0.4)',
                                      flex: '1',
                                      margin: '5px',
                                      // maxWidth: 'fit-content'

                                  }}>
                                {/*<label htmlFor="prompt" className="col-fixed" style={{width: '170px'}}>Abfrage*/}
                                {/*    auswählen</label>*/}

                                <div className="prompts" style={{

                                    // width: '40%'
                                }}>

                                    <SelectButton value={selectedQuestionsValues}
                                                  options={textList}
                                                  style={{
                                                      // margin: '0 0 0 0',
                                                      // paddingLeft: '70px',


                                                  }}
                                                  onChange={(e) => setSelectedQuestionsValues(e.value)}
                                                  multiple
                                    />


                                </div>

                                <div className="length"

                                >
                                    <div
                                        hidden={lengthDropdownHidden}
                                    >
                                        <Dropdown
                                            value={selectedLength}
                                            onChange={(e) => setSelectedLength(e.value)}
                                            options={lengths}
                                            optionLabel="name"
                                            placeholder="50%"
                                            className="lengths"
                                            style={{
                                                height: '40px',
                                                marginBottom: '8px'
                                                // verticalAlign:'middle'

                                                // paddingLeft: '70px',

                                            }}
                                        />

                                    </div>
                                    <div
                                        hidden={mediaSelectDropdownHidden}
                                    >
                                        <Dropdown
                                            value={selectedSocialMedia}
                                            onChange={(e) => setSelectedSocialMedia(e.value)}
                                            options={socialMedias}
                                            optionLabel="name"
                                            placeholder="Wähle soziale Media"
                                            className="socialMedias"
                                            style={{
                                                height: '40px',
                                                // verticalAlign:'middle'

                                                // paddingLeft: '70px',

                                            }}
                                        />
                                    </div>
                                </div>
                            </Card>


                            <Card title={"Aufgaben im Zusammenhang mit Wort- und Satzanalyse"}
                                  style={{
                                      backgroundColor: 'rgba(45,149,150,0.4)',
                                      flex: '1',
                                      margin: '5px',


                                  }}>
                                {/*<label htmlFor="prompt" className="col-fixed" style={{width: '170px'}}>Abfrage*/}
                                {/*    auswählen</label>*/}
                                <div style={{
                                    alignItems: 'center',
                                    // borderStyle: 'double',
                                    // maxWidth:'fit-content',
                                    // paddingLeft: '20%',
                                    // paddingRight: '10%'


                                    // width: '40%'
                                }}>
                                    <div
                                        className="prompts"
                                        style={{
                                            marginLeft: '100px',
                                            // borderStyle: 'solid'
                                            // minWidth:'fit-content',
                                            // width: '40%'
                                        }}>
                                        <SelectButton value={selectedQuestionsValues}
                                                      options={wordSentenceList}
                                                      style={{
                                                          // margin: '0 0 0 0',
                                                          // paddingLeft: '70px',
                                                          // alignItems: 'center',
                                                          // textAlign: 'center'


                                                      }}
                                                      onChange={(e) => setSelectedQuestionsValues(e.value)}
                                                      multiple
                                        />

                                    </div>
                                </div>
                            </Card>


                            <Card title={"Aufgaben im Zusammenhang mit SEO / Keyword Optimierung"}
                                  style={{
                                      backgroundColor: 'rgba(154,208,194,0.4)',
                                      flex: '1',
                                      margin: '5px'
                                  }}>
                                {/*<label htmlFor="prompt" className="col-fixed" style={{width: '170px'}}>Abfrage*/}
                                {/*    auswählen</label>*/}
                                <div>
                                    <div className="prompts" style={{


                                        // width: '40%'
                                    }}>
                                        <SelectButton value={selectedQuestionsValues}
                                                      options={seoList}
                                                      style={{
                                                          // margin: '0 0 0 0',
                                                          // paddingLeft: '70px',

                                                      }}
                                                      onChange={(e) => setSelectedQuestionsValues(e.value)}
                                                      multiple
                                        />
                                    </div>
                                </div>
                            </Card>

                        </div>


                        <div style={{
                            display: 'flex',
                            flexWrap: 'wrap'
                        }}>

                            <div
                                style={{
                                    // borderStyle:'double',
                                    flex: 1,
                                    margin: '10px'
                                }}>


                                <label
                                    htmlFor="message"
                                    className="col-fixed"
                                    style={{
                                        width: '170px',
                                        color: '#1e4059'
                                    }}
                                >Zusätzliche Anweisungen</label>
                                <div className="field grid">

                                    <div className="col">
                                        <InputTextarea id="message"
                                                       onChange={(e) => setAdditionalPrompt(e.target.value)}
                                                       autoResize
                                                       style={{
                                                           width: '100%',
                                                           // width: '630px',
                                                           // height: '20px',
                                                           // minHeight: '20px'
                                                       }}
                                                       placeholder="Bsp. angesprochen sind Fachkräfte aus der Industriebranche"
                                            // disabled={!isTextareaEnabled}
                                            // value={customer}
                                            // onChange={(e) => setCustomer(e.target.value)}
                                                       required
                                            // className={customer !== '' ? "" : "p-invalid mr-2"}
                                        />
                                    </div>
                                </div>

                            </div>


                            <div style={{
                                flex: 1,
                                margin: '10px',
                                // borderStyle: 'double'
                            }}
                            >
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    // alignItems: 'center'
                                }}>

                                    <label style={{marginBottom: '5px'}}>Die ausgewählten Aufgaben in voller
                                        Länge</label>
                                    {/*<p style={{ width: '100%' }}> {selectedQuestionsValues}</p>*/}
                                    <div style={{
                                        width: '100%',
                                        borderStyle: 'solid',
                                        borderColor: 'rgba(30,64,89,0.3)',
                                        borderWidth: '1px',
                                        minHeight: '57px'
                                    }}>
                                        <p>
                                            {selectedQuestionsValues.map((value, index) => (
                                                <span key={index}>
                                                {index + 1}. {value}
                                                    <br/>
                                                    {index !== selectedQuestionsValues.length - 1 && <hr/>}
                                                </span>
                                            ))}
                                        </p>
                                    </div>
                                </div>
                            </div>


                        </div>


                        <div style={{
                            display: 'flex',
                            flexWrap: 'wrap'
                        }}>
                            <div style={{
                                flex: 1,
                                margin: '10px'
                            }}>
                                <label
                                    htmlFor="message"
                                    className="col-fixed"
                                    style={{
                                        width: '170px',
                                        color: '#1e4059'
                                    }}
                                >Text eingeben</label>
                                <div className="field grid">

                                    <div className="col">
                                        <InputTextarea id="message"
                                            // onChange={(e) => setPrompt(e.target.value)}
                                                       onChange={handleInputText}
                                                       autoResize
                                                       style={{
                                                           width: '100%',
                                                           // width: '630px',
                                                           minHeight: '100px'
                                                       }}
                                                       placeholder="Text hier eingeben"
                                            // value={customer}
                                            // onChange={(e) => setCustomer(e.target.value)}
                                                       required
                                            // className={customer !== '' ? "" : "p-invalid mr-2"}
                                        />
                                    </div>

                                </div>


                                <div style={{

                                    marginLeft: '5px',
                                    marginBottom: '10px',
                                    borderBottom: 'solid',
                                    borderColor: 'rgba(30,64,89,0.3)',
                                    borderWidth: '1px',
                                    color: '#1e4059'

                                }}>

                                    <div className="label-with-button"
                                         style={{
                                             display: 'inline-block',
                                             maxWidth: ''
                                         }}
                                    >
                                        <label

                                        >Anzahl der Textzeichen: </label>
                                        <Button
                                            style={{
                                                borderStyle: 'ridge',
                                                borderColor: 'rgb(236,236,236)',
                                                backgroundColor: 'rgba(30,64,89,0.50)',
                                                height: 'fit-content',
                                                width: 'fit-content',
                                                padding: '0px',
                                                paddingRight: '2px',
                                                paddingLeft: '2px',
                                                fontWeight: '200',
                                                marginRight: '10px'
                                            }}
                                        >{JSON.stringify(countCharactersAndWords(inputText).characters)}</Button>

                                    </div>


                                    <div className="label-with-button"
                                         style={{
                                             display: 'inline-block'
                                         }}
                                    >
                                        <label
                                            style={{}}
                                        >Anzahl der Textwörter: </label>
                                        <Button
                                            value={countCharactersAndWords(inputText).characters}
                                            style={{
                                                borderStyle: 'ridge',
                                                borderColor: 'rgb(236,236,236)',
                                                backgroundColor: 'rgba(30,64,89,0.50)',
                                                height: 'fit-content',
                                                width: 'fit-content',
                                                padding: '0px',
                                                fontWeight: '200',
                                                paddingRight: '2px',
                                                paddingLeft: '2px',

                                            }}
                                        >
                                            {JSON.stringify(countCharactersAndWords(inputText).words)}
                                        </Button>
                                    </div>


                                </div>


                            </div>

                            <div style={{
                                flex: 1,
                                margin: '10px'
                            }}>
                                <label
                                    htmlFor="message"
                                    className="col-fixed"
                                    style={{
                                        width: '170px',
                                        color: '#1e4059'
                                    }}
                                >Die gesamte Anfrage</label>
                                <i
                                    className="pi pi-info-circle"
                                    title="Die Zeit, die für die Antwort benötigt wird, hängt von der Anzahl der gesamten Anfragezeichen und der Komplexität der Anfrage ab."
                                    style={{color: 'green'}}
                                >
                                </i>
                                <div className="field grid">

                                    <div className="col">
                                        <InputTextarea id="message-two"
                                            // disabled
                                                       value={prompt}

                                                       autoResize
                                                       style={{
                                                           width: '100%',
                                                           // width: '630px',
                                                           minHeight: '100px'
                                                       }}
                                                       placeholder="Die gesamte Anfrage"
                                            // value={customer}
                                            // onChange={(e) => setCustomer(e.target.value)}
                                                       required
                                            // className={customer !== '' ? "" : "p-invalid mr-2"}
                                        />
                                        {/*<p >{prompt}</p>*/}

                                    </div>

                                </div>


                                <div style={{

                                    marginLeft: '5px',
                                    marginBottom: '10px',
                                    borderBottom: 'solid',
                                    borderColor: 'rgba(30,64,89,0.3)',
                                    borderWidth: '1px',
                                    color: '#1e4059'

                                }}>


                                    <label
                                        style={{}}
                                    >Zeichenanzahl der gesamten Anfrage: </label>
                                    <Button
                                        style={{
                                            borderStyle: 'ridge',
                                            borderColor: 'rgb(236,236,236)',
                                            backgroundColor: 'rgba(30,64,89,0.50)',
                                            height: 'fit-content',
                                            width: 'fit-content',
                                            padding: '0px',
                                            paddingRight: '2px',
                                            paddingLeft: '2px',
                                            fontWeight: '200',
                                            marginRight: '10px'
                                        }}
                                    >{JSON.stringify(countCharactersAndWords(prompt).characters)}</Button>


                                    <label
                                        style={{}}
                                    >Wortanzahl der gesamten Anfrage: </label>
                                    <Button
                                        style={{
                                            borderStyle: 'ridge',
                                            borderColor: 'rgb(236,236,236)',
                                            backgroundColor: 'rgba(30,64,89,0.50)',
                                            height: 'fit-content',
                                            width: 'fit-content',
                                            padding: '0px',
                                            fontWeight: '200',
                                            paddingRight: '2px',
                                            paddingLeft: '2px',

                                        }}
                                    >{JSON.stringify(countCharactersAndWords(prompt).words)}</Button>

                                </div>


                            </div>

                        </div>


                        {/*<div style={{*/}
                        {/*    marginLeft: '5px',*/}
                        {/*    marginBottom: '10px',*/}
                        {/*}}>*/}


                        {/*    <label>Anzahl der Textzeichen: </label>*/}
                        {/*    <Button*/}
                        {/*        style={{*/}
                        {/*            backgroundColor: 'black',*/}
                        {/*            height: 'fit-content',*/}
                        {/*            width: 'fit-content',*/}
                        {/*            padding: '0px'*/}
                        {/*        }}*/}
                        {/*    >{JSON.stringify(countCharactersAndWords(prompt).characters)}</Button>*/}


                        {/*    <label*/}
                        {/*        style={{*/}
                        {/*            marginLeft: '20px',*/}
                        {/*        }}*/}
                        {/*    >Anzahl der Textwörter: </label>*/}
                        {/*    <Button*/}
                        {/*        style={{*/}
                        {/*            height: 'fit-content',*/}
                        {/*            width: 'fit-content',*/}
                        {/*            padding: '0px',*/}
                        {/*            backgroundColor: 'black',*/}
                        {/*        }}*/}
                        {/*    >{JSON.stringify(countCharactersAndWords(prompt).words)}</Button>*/}


                        {/*</div>*/}


                        <Button
                            raised
                            outlined
                            text
                            className="col-fixed"
                            onClick={handlePromptSend}
                            style={{
                                fontWeight: '500',
                                width: '170px',
                                backgroundColor: 'rgba(43,112,93,0.73)',
                                padding: '5px',
                                display: 'inline-block',
                                color: 'whitesmoke'
                            }}
                        >Nachricht
                            senden</Button>
                        <br/> <br/>

                        <hr/>


                        <div className="add-sett-group">
                            <label className="col-fixed"
                                   style={{
                                       color: '#1e4059'
                                   }}
                            >Zusätzliche Einstellungen</label>
                            <ToggleButton
                                id="toggle-button-sett"
                                checked={checkedAdditionalSettings}
                                onChange={handleToggleSettings}
                                onLabel="nein"
                                offLabel="ja"
                            />
                        </div>


                        {/*// //////////////////////////////////////////////////////////////////////////////////// */}


                        <div hidden={isSettingsHidden} className="add-sett-elements">


                            <div style={{display: 'flex'}}>
                                <label className="col-fixed"
                                       style={{
                                           color: '#1e4059'
                                       }}
                                >KI Version</label>
                                <SelectButton
                                    className="model-buttons"
                                    value={model}
                                    onChange={(e) => setModel(e.value)}
                                    optionLabel="name"
                                    options={models}
                                />
                                <div className="version-info">
                                    <i
                                        className="pi pi-info-circle"
                                        title="      Während GPT-3.5 bereits über bemerkenswerte Fähigkeiten zur Verarbeitung natürlicher Sprache verfügt,
                                    zeichnet sich GPT-4 durch seine verbesserten Denk- und Problemlösungsfähigkeiten aus.
                                    Dies macht das Modell in vielen spezifischen Anwendungsfällen überlegen.
                                    • Version 4 kann langsamer sein als Version 3.5
                                    • Version 4o ist 2x schneller und viel effizienter
                                    - gpt-3.5: Trainingsdaten bis September 2021
                                    - gpt-4: Trainingsdaten bis Dezember 2023
                                    - gpt-4o: Trainingsdaten bis Oktober 2023"
                                    >
                                    </i>

                                </div>


                                <div style={{
                                    borderLeft: '1px solid white',
                                    height: '30px',
                                    marginLeft: '25px',

                                }}></div>


                                <div className="temp-elements">
                                    <label className="temp-label"> Temperatur
                                    </label>
                                    <Dropdown

                                        id="temp-drop"
                                        options={temperatureList}
                                        value={temperature}
                                        onChange={handleTemperatureChange}
                                    >Temperature</Dropdown>
                                </div>
                                <div className="version-info">
                                    <i
                                        className="pi pi-info-circle"
                                        title="Setzen Sie die Temperatur auf 0.1 für eine direkte,
                                    weniger kreative und erwartete Antwort ein,
                                    oder Setzen Sie die Temperatur auf 2.0 für eine kreativere Antwort"
                                    >
                                    </i>
                                </div>

                            </div>

                        </div>


                        {/*// //////////////////////////////////////////////////////////////////////////////////// */}


                    </Card>


                </div>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    {loading ? <ProgressSpinner strokeWidth="8"

                    /> : false}
                </div>
            </BlockUI>


            <Messages ref={toastRef}/>


            <div style={{textAlign: 'center'}} hidden={!loading}>
                <Button

                    style={{
                        backgroundColor: 'rgba(206,16,16,0.8)',
                        color: 'rgb(236,236,236)',
                        padding: '10px 60px 10px 60px',
                        fontWeight: '700'

                    }}
                    label="Abbrechen"
                    severity="danger"
                    outlined
                    onClick={stopBlocking}
                > </Button>
            </div>


            <Card
                style={{margin: '30px', backgroundColor: 'rgba(38,80,115,0.15)'}}
                className="card-background"
            >

                {/*<Panel header="Ergebnis">*/}
                {/*    /!*<div style={{ whiteSpace: 'pre-line' }}>{responseData}</div>*!/*/}
                {/*    <div style={{whiteSpace: 'pre-line'}} dangerouslySetInnerHTML={{__html: responseData}}/>*/}

                {/*</Panel>*/}



                <Panel header="Ergebnis">
                    <div>
                        <ReactMarkdown
                            className="markdown-content"
                        >
                            {responseData}
                        </ReactMarkdown>
                    </div>
                </Panel>

                {/*<Panel header="Ergebnis">*/}
                {/*    <div style={{ whiteSpace: 'pre-line' }}>*/}
                {/*        <ReactMarkdown*/}
                {/*            components={{*/}
                {/*                code({ node, inline, className, children, ...props }) {*/}
                {/*                    const match = /language-(\w+)/.exec(className || '');*/}
                {/*                    return !inline && match ? (*/}
                {/*                        <SyntaxHighlighter*/}
                {/*                            style={oneDark} // Style for the syntax highlighting*/}
                {/*                            language={match[1]}*/}
                {/*                            PreTag="div"*/}
                {/*                            {...props}*/}
                {/*                        >*/}
                {/*                            {String(children).replace(/\n$/, '')}*/}
                {/*                        </SyntaxHighlighter>*/}
                {/*                    ) : (*/}
                {/*                        <code className={className} {...props}>*/}
                {/*                            {children}*/}
                {/*                        </code>*/}
                {/*                    );*/}
                {/*                }*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            {responseData}*/}
                {/*        </ReactMarkdown>*/}
                {/*    </div>*/}
                {/*</Panel>*/}



                <div style={{display: 'flex', alignItems: 'center', height: '50px'}}>
                    <Button className="copyMsg"
                            severity="success"
                            text
                            onClick={copyToClipboard}
                            raised
                    > <i className="pi pi-copy" style={{fontSize: '1.5rem'}}></i></Button>
                    <p
                        className="hide"
                    >text kopieren</p>
                </div>
            </Card>

        </>


    );
}