import { Accordion, AccordionTab } from 'primereact/accordion';
import {useEffect, useState} from "react";
import {AiChatMetricsService} from "../metrics/services/AiChatMetricsService";
import {Button} from "primereact/button";
import "./AiChatMetrics.css";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {Card} from "primereact/card";


export function AiChatMetrics() {

    const [logfileInfo, setLogfileInfo] = useState(null);
    const [statistics, setStatistics] = useState([]);

    const aiChatMetricsService = new AiChatMetricsService();
//--------------------------------------------------------------------------------------
//     aiChatMetricsService.fetchLogfileInfo().then(response => {
//       setLogfileInfo(response);
//     })
//
//
//     aiChatMetricsService.fetchStatistics().then(response => {
//         setStatistics(response);
//         console.log(statistics);
//     })

    useEffect(() => {
        aiChatMetricsService.fetchLogfileInfo().then(response => {
            setLogfileInfo(response);
        });

        aiChatMetricsService.fetchStatistics().then(response => {
            setStatistics(response);
            console.log(response); // Move the console.log here
        });
    }, []); // Empty dependency array to run only once on component mount
//--------------------------------------------------------------------------------------
    const formatDate = (dateString:string) => {
        const [year, month, day] = dateString.split('-');
        return `${day}.${month}.${year}`;
    };
//--------------------------------------------------------------------------------------
    return (
        <>
            <h1 className="header-metrics">Metrics</h1>

            <Card className="card-metrics">
                <Accordion activeIndex={1} >
                    <AccordionTab header="Nutzungsstatistiken / täglich" className="acordian">
                <DataTable  value={statistics} tableStyle={{ minWidth: '50rem' }} paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} sortField="requestDate" sortOrder={-1}>
                    <Column sortable field="endpointName" header="Endpoint"></Column>
                    {/*<Column sortable  field="requestDate" header="Anfragedatum"></Column>*/}
                    <Column sortable field="requestDate" header="Anfragedatum" body={(rowData) => formatDate(rowData.requestDate)} />
                    <Column sortable  field="requestCount" header="Anzahl der Anfragen"></Column>

                </DataTable>
                    </AccordionTab>
                </Accordion>
            </Card>

            <Card className="card-metrics">
                <div className="log-file">
                <Accordion activeIndex={1} >
                    <AccordionTab header="Logdatei" className="acordian">
                        <pre className="m-0">
                            {logfileInfo || "Loading..."}
                        </pre>
                    </AccordionTab>
                </Accordion>
                </div>
            </Card>

        </>
    );
}