import React from 'react';
import './App.scss';
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import 'primeflex/primeflex.css'

import {HashRouter, Navigate, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {Home} from "./components/Home";
import {AiChat} from "./ai/AiChat";
// import {NavigationBar} from "./components/NavigationBar";
import NavigationBar from "./components/NavigationBar";
import {AiChatMetrics} from "./metrics/AiChatMetrics";
// import {ReactKeycloakProvider} from "@react-keycloak/web";
// import keycloak from "./keycloak";



export default function App() {

    if (!location) {
        console.error('Location is undefined.');
        return null;
    }
console.log("location.href is: " + location.href);
    console.log("location is: " + location);
    return (

            <div>
                <HashRouter>
                    <NavigationBar/>
                    <Routes>
                        <Route path="/" element={<Navigate to="/home"/>}/>
                        <Route path='/home' element={<Home/>}/>
                        <Route path='/redaktion-ai-chat/' element={<AiChat/>}/>
                        <Route path='/70771/metrics/' element={<AiChatMetrics/>}/>
                    </Routes>

                </HashRouter>
            </div>

    );
}

