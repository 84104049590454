import axios from "axios";
import config from "../../config";
export class AiChatMetricsService {


//------------------------------------------------------------------------------------------------------------------

        async fetchLogfileInfo() {
            const response = await axios.get(config.REACT_APP_AI_CHAT_ACTUATOR_URL, {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
            });
            return response.data;
        }

//------------------------------------------------------------------------------------------------------------------

        async fetchStatistics(){
            return axios.get(config.REACT_APP_AI_CHAT_REQUEST_STATISTICS, {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
            }).then(response => response.data);
}

//------------------------------------------------------------------------------------------------------------------

    }

