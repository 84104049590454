import axios from "axios";
import config from "../../config";



    export class AiChatService {

    sendPromptToBackend(prompt: string, model: string, temperature: number, signal: AbortSignal) {
        console.log("Prompt is: " + JSON.stringify(prompt));
        console.log("Temperature is: " + JSON.stringify(temperature));
        console.log("Model is: " + JSON.stringify(model));
        const source = axios.CancelToken.source();
        // const temperatureToSend = parseFloat(temperature.toFixed(1));
        // const encodedPrompt = encodeURIComponent(prompt);

        // console.log("encodedPrompt is : " + JSON.stringify(encodedPrompt));

        const requestData = {
            prompt,
            model,
            temperature
        };
console.log("requestData is : " + JSON.stringify(requestData));
        const requestConfig = {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Accept': '*/*',
                // 'Accept-Encoding': 'gzip, deflate, br',
                // 'Connection': 'keep-alive'
            },
            cancelToken: source.token
        };

        const request = axios.post(config.REACT_APP_AI_CHAT_URL, requestData, requestConfig)
            .then(response => response.data)
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log('Request was canceled', error.message);
                } else {
                    console.error("Error in Ai chat service, error is : ", error);
                    console.error("Error in Ai chat service: error.response ? error.response.data : error.message", error.response ? error.response.data : error.message);
                    throw new Error("There was an error in the AI chat service. Please try again");
                }
            });

        console.log("The targeted ai-service endpoint is : " + config.REACT_APP_AI_CHAT_URL);

        // Attach the abort method to the signal
        if (signal) {
            signal.addEventListener('abort', () => {
                source.cancel('Operation canceled by the user.');
            });
        }
        return request;
    }

//------------------------------------------------------------------------------------------------------------------

        // async fetchLogfileInfo() {
        //
        //     const response = await axios.get('http://localhost:9098/actuator/logfile', {
        //         headers: {
        //             'Content-Type': 'application/json',
        //             'Access-Control-Allow-Origin': '*',
        //         },
        //     });
        //     return response.data;
        // }
//------------------------------------------------------------------------------------------------------------------


    }

        // fetchLogfileInfo() {
        //
        //     return axios.get('http://localhost:9098/actuator/logfile', {
        //         headers: {
        //             'Content-Type': 'application/json',
        //             'Access-Control-Allow-Origin': '*',
        //         },
        //     }).then(response => response.data);
        // }
//------------------------------------------------------------------------------------------------------------------


        // try {
        //     const response = await axios.get('http://localhost:9098/actuator/logfile');
        //     return response.data; // Return the response data
        // } catch (error) {
        //     console.error('Error fetching logfile info:', error);
        //     throw new Error('There was an error fetching logfile info. Please try again.');
        // }








// export class AiChatService {

// sendPromptToBackend(prompt: string, model: string, temperature: number) {
//
//     console.log("Prompt is: " + JSON.stringify(prompt));
//
//     return axios.get(config.REACT_APP_AI_CHAT_URL, {
//         params: {prompt, model, temperature},
//         headers: {
//             'Content-Type': 'application/json',
//             'Access-Control-Allow-Origin': '*'
//         },
//     }).then(response => response.data)
//         .catch(error => {
//             console.error("Error in Ai chat service: ", error);
//             throw new Error("There was an error in the AI chat service. Please try again")
//         });
// }







// sendPromptToBackend(prompt: string, model: string, temperature: number, signal: AbortSignal) {
//     console.log("Prompt is: " + JSON.stringify(prompt));
//     console.log("Temperature is: " + JSON.stringify(temperature));
//     console.log("Model is: " + JSON.stringify(model));
//     const source = axios.CancelToken.source();
//
//     const encodedPrompt = encodeURIComponent(prompt);
//
//     const requestConfig = {
//         params: { prompt: encodedPrompt, model, temperature },
//         headers: {
//             'Content-Type': 'application/json',
//             'Access-Control-Allow-Origin': '*',
//             'Accept': '*/*',
//             // 'Accept-Encoding': 'gzip, deflate, br',
//             // 'Connection': 'keep-alive'
//         },
//         cancelToken: source.token
//     };
//
//     const request = axios.get(config.REACT_APP_AI_CHAT_URL, requestConfig)
//         .then(response => response.data)
//         .catch(error => {
//             if (axios.isCancel(error)) {
//                 console.log('Request was canceled', error.message);
//             } else {
//                 console.error("Error in Ai chat service: ", error);
//                 throw new Error("There was an error in the AI chat service. Please try again");
//             }
//         });
//     console.log("The targeted ai-service endpoint is : " + config.REACT_APP_AI_CHAT_URL)
//     // Attach the abort method to the signal
//     if (signal) {
//         signal.addEventListener('abort', () => {
//             source.cancel('Operation canceled by the user.');
//         });
//     }
//
//     return request;
// }