import React from 'react';
import { Menubar } from 'primereact/menubar';
import {useNavigate} from "react-router-dom";

export default function NavigationBar() {
    const navigate = useNavigate();
    const items = [
        {
            label: 'Home',
            // icon: 'pi pi-home',
            command: () => {
                navigate("/");
            }
        }
    ];
    const logo = <img alt="logo" src="./images/konradin_mediengruppe_4c.png" height="40" className="mr-2"
                      style={{marginRight: '50px'}}/>
    return (
        <div
            style={{
                // backgroundImage: "url('./images/chatGptBcg.jpg')",
                // backgroundSize: "cover"
            }}
        >
            <Menubar
                model={items}
                end={logo}
                style={{
                    // borderStyle: 'double',
                    // borderWidth: '50px',
                    borderBottom: 'ridge',
                    borderColor: 'rgba(38,80,115,0.38)',
                    backgroundColor: 'white',
                    fontWeight: '400',
                    // color: 'white',
                    fontSize: '25px',


                }}
            />
        </div>
    )
}