export default {
                                    //*** PRODUCTION ***//

//---------------------------------------------------------------------------------------------------------------------

    // AI_CHAT on port 8082// 8081 is backend

    REACT_APP_AI_CHAT_URL: "https://ki-service-backend.konradin-online.de/chat/",
    REACT_APP_AI_CHAT_ACTUATOR_URL: "https://ki-service-backend.konradin-online.de/actuator/logfile",
    REACT_APP_AI_CHAT_REQUEST_STATISTICS: "https://ki-service-backend.konradin-online.de/request-statistics/"
    // REACT_APP_AI_CHAT_URL: "https://konrep-staging.konradin-online.de/chat-gpt/chat/"
    // REACT_APP_AI_CHAT_URL: "https://ki-service-staging.konradin-online.de/chat-gpt/chat/"
    // REACT_APP_AI_CHAT_URL: "https://ki-service-staging.konradin-online.de:8082/chat/"

//---------------------------------------------------------------------------------------------------------------------

}