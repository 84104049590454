export default {
                                    //*** DEVELOPMENT ***//

//---------------------------------------------------------------------------------------------------------------------

    // AI_CHAT 9098 //

    REACT_APP_AI_CHAT_URL: "http://localhost:9098/chat/",
    REACT_APP_AI_CHAT_ACTUATOR_URL: "http://localhost:9098/actuator/logfile",
    REACT_APP_AI_CHAT_REQUEST_STATISTICS: "http://localhost:9098/request-statistics/"
    // REACT_APP_AI_CHAT_URL: "http://localhost:8082/chat/",
    // REACT_APP_AI_CHAT_URL: "https://ki-service-staging.konradin-online.de:8082/chat/"
    // REACT_APP_AI_CHAT_URL: "https://ki-service-backend-staging.konradin-online.de/chat/"

//---------------------------------------------------------------------------------------------------------------------

}